import styled from 'styled-components'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'

function DownloadButton({ downloadUrl }) {
  return (
    <Container>
      <a href={downloadUrl} download rel="noreferrer" target="_blank">
        <FontAwesomeIcon icon={faCloudDownloadAlt} size="3x" color="black" />{' '}
        Som para atividade
      </a>
    </Container>
  )
}

export default DownloadButton

const Container = styled.div`
  margin-top: 60px;

  a {
    color: black;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }
`
