import VideoPageComponent from "../../Components/VideoPage";

const VideoPage = () => {
  return (
    <VideoPageComponent
      videoSrc="https://www.codeahead.pt/clients/pontocoletivo/videos/episodio2.mp4"
      title="Missão 2"
    />
  );
};

export default VideoPage;
