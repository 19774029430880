import { useContext } from 'react'
import styled from 'styled-components'
import { MouseContext } from '../../context/mouse-context'
import DownloadButton from '../DownloadButton'

const VIDEO_WIDTH = 70 // PERCENTAGE
const HEADER_MARGIN = (100 - VIDEO_WIDTH) / 2

const VideoPageComponent = ({ videoSrc, title, downloadFile }) => {
  const { cursorType, cursorChangeHandler } = useContext(MouseContext)

  return (
    <PageContainer>
      <Header>
        <LogoContainer
          onMouseEnter={() => cursorChangeHandler('hovered')}
          onMouseLeave={() => cursorChangeHandler('')}
        >
          <img src="/images/logo.png" alt="" />
        </LogoContainer>
        <TitleContainer
          onMouseEnter={() => cursorChangeHandler('hovered')}
          onMouseLeave={() => cursorChangeHandler('')}
        >
          <h1>{title}</h1>
        </TitleContainer>
      </Header>
      <StyledVideo
        width="400"
        preload="auto"
        src={videoSrc}
        type="video/mp4"
        autoPlay={true}
        controls
        disablePictureInPicture
        controlsList="nodownload noplaybackrate"
        muted
      ></StyledVideo>
      {!!downloadFile && <DownloadButton downloadUrl={downloadFile} />}
      <Footer>
        <div
          onMouseEnter={() => cursorChangeHandler('hovered')}
          onMouseLeave={() => cursorChangeHandler('')}
        >
          <span>Todos os direito reservados | Missão Sem Titulo</span>
        </div>
      </Footer>
    </PageContainer>
  )
}

export default VideoPageComponent

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100vh;
`
const Header = styled.div`
  width: 100%;
  height: 20vh;

  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Footer = styled(Header)`
  display: flex;
  align-items: end;
  justify-content: center;
  height: 10vh;

  > div {
    margin-bottom: 12px;
  }

  span {
    font-size: 12px;
  }
`

const LogoContainer = styled.div`
  height: 80%;
  margin-left: ${HEADER_MARGIN}%;

  img {
    height: 100%;
  }
`

const TitleContainer = styled.div`
  margin-right: ${HEADER_MARGIN}%;
`

const StyledVideo = styled.video`
  width: ${VIDEO_WIDTH}%;
  height: auto;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`
