import VideoPageComponent from "../../Components/VideoPage";

const VideoPage = () => {
  return (
    <VideoPageComponent
      videoSrc="https://www.codeahead.pt/clients/pontocoletivo/videos/episodio1.m4v"
      title="Missão 1"
    />
  );
};

export default VideoPage;
