import VideoPageComponent from "../../Components/VideoPage";

const Video1PageComponent = () => {
  return (
    <VideoPageComponent
      videoSrc="https://www.codeahead.pt/clients/pontocoletivo/videos/episodio7.mp4"
      title="Missão 7"
    />
  );
};

export default Video1PageComponent;
