import VideoPageComponent from "../../Components/VideoPage";

const VideoPage = () => {
  return (
    <VideoPageComponent
      videoSrc="https://www.pontocoletivo.com/missaosemtitulo/media/intro.mp4"
      title="Introdução"
    />
  );
};

export default VideoPage;
