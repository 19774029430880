import VideoPageComponent from "../../Components/VideoPage";

const Video1PageComponent = () => {
  return (
    <VideoPageComponent
      videoSrc="https://www.codeahead.pt/clients/pontocoletivo/videos/episodio8.mp4"
      title="Missão 8"
    />
  );
};

export default Video1PageComponent;
